<template>
  <div>
    <dv-loading v-if="loading" style="height: 400px;"></dv-loading>
    <Chart v-else :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {formatTime} from "@/utils";
export default {
  data () {
    return {
      loading:true,
      drawTiming: null,
      cdata: {
        year: new Date().getFullYear(),
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 5,
        weekMaxData: [],
        weekLineData: []
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    this.drawTimingFn();
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      // this.setData();
      this.getData();
      this.drawTiming = setInterval(() => {
        this.getData();
      }, 6000);
    },
    getData(){
      this.$api.screen.getDischargeInfo({
        //取当前日期
        date:formatTime(new Date(), 'yyyy-MM-dd'),
        company_id:1,
      }).then(res=>{
        // this.cdata.maxData = res.data.maxData;
        this.setData(res);
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
        this.loading = false;
      })
    },

    setData (data) {
      // 清空轮询数据
      this.cdata.weekCategory = [];
      this.cdata.weekMaxData = [];
      this.cdata.weekLineData = [];
      this.cdata.radarData = [];
      this.cdata.radarDataAvg = [];

      let dateBase = new Date();
      this.cdata.year = dateBase.getFullYear();
      let service_stat_list = data.service_stat_list;
      let radarDayData = [0,0,0,0]
      service_stat_list.forEach(item=>{
        this.cdata.weekCategory.push(item.stat_date);
        this.cdata.weekLineData.push(item.avg_discharge);
        if(item.avg_discharge>this.cdata.maxData){
          this.cdata.maxData =Math.round(item.avg_discharge * 1.5,0)
        }

        if(item.avg_discharge<=2){
          //超低能耗
          radarDayData[3]++;

        }else if(item.avg_discharge<=4) {
          //常规能耗
          radarDayData[2]++;
        }else if(item.avg_discharge<=6) {
          //较高能耗
          radarDayData[1]++;
        }else {
          //超高能耗
          radarDayData[0]++;
        }
      })
      this.cdata.radarDataAvg = radarDayData;

      //取最大值
      // // 周数据
      // for (let i = 0; i < 7; i++) {
      //   // 日期
      //   let date = new Date();
      //   this.cdata.weekCategory.unshift([date.getMonth() + 1, date.getDate()-i].join("/"));
      //
      //   // 折线图数据
      //   this.cdata.weekMaxData.push(this.cdata.maxData);
      //   let distance = Math.round(Math.random() * 11000 + 500);
      //   this.cdata.weekLineData.push(distance);
      //
      //   // 雷达图数据
      //   // 我的指标
      //   let averageSpeed = +(Math.random() * 5 + 3).toFixed(3);
      //   let maxSpeed = averageSpeed + +(Math.random() * 3).toFixed(2);
      //   let hour = +(distance / 1000 / averageSpeed).toFixed(1);
      //   let radarDayData = [distance, averageSpeed, maxSpeed, hour];
      //   this.cdata.radarData.unshift(radarDayData);
      //
      //   // 平均指标
      //   let distanceAvg = Math.round(Math.random() * 8000 + 4000);
      //   let averageSpeedAvg = +(Math.random() * 4 + 4).toFixed(3);
      //   let maxSpeedAvg = averageSpeedAvg + +(Math.random() * 2).toFixed(2);
      //   let hourAvg = +(distance / 1000 / averageSpeed).toFixed(1);
      //   let radarDayDataAvg = [
      //     distanceAvg,
      //     averageSpeedAvg,
      //     maxSpeedAvg,
      //     hourAvg
      //   ];
      //   this.cdata.radarDataAvg.unshift(radarDayDataAvg);
      // }

    }
  }
};
</script>

<style lang="scss" scoped>
</style>