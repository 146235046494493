<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b ">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon" fill="#FFF"></icon>
        </span>
        <span class=" text mx-2 mb-1 pl-3">运营总充电排行榜</span>
        <dv-loading v-if="loading"></dv-loading>
        <dv-scroll-ranking-board v-else class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>今日出勤率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>今日里程达标率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water" style="text-align: center">
          <span >每辆里程指标:{{mileage_standard}}km</span>
<!--          <dv-water-level-pond class="dv-wa-le-po" :config="water" />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartRate'

export default {
  data() {
    return {
      loading:true,
      mileage_standard: 200,
      titleItem: [
        {
          title: '今日运营车辆',
          number: {
            number: [0,0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}/{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日运营场地',
          number: {
            number: [0,0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}/{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日在岗人数',
          number: {
            number: [0,0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}/{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日累计里程(km)',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日累计耗电(kwh)',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日累计充电(kwh)',
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        }
      ],
      ranking: {
        data: [
          // {
          //   name: '云南华联锌铟',
          //   value: 20000
          // },
          // {
          //   name: '漳平龙钢',
          //   value: 500
          // },
          // {
          //   name: '中维动力',
          //   value: 0
          // },
        ],
        carousel: 'single',
        unit: 'kwh'
      },
      water: {
        data: [0, 0],
        shape: 'roundRect',
        formatter: '{value}%',
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 0,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 0,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ]
    }
  },
  components: {
    CenterChart
  },

  //定时器
  mounted() {
    this.getData()
    this.timer = setInterval(() => {
      this.getData()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 获取数据
    getData() {
      // 获取数据
      this.$api.screen.getServiceInfo().then(res => {
        this.titleItem[0].number.number = [res.run_count??0, res.total_run_count??0]
        this.titleItem[1].number.number = [res.run_service_count??0, res.total_service_count??0]
        this.titleItem[2].number.number = [res.user_count??0, res.total_user_count??0]
        this.titleItem[3].number.number = [res.today_mileage??0]
        this.titleItem[4].number.number = [res.today_discharge_total_kwh??0]
        this.titleItem[5].number.number = [res.today_charge_total_kwh??0]

        this.rate[0].tips = res.run_count_percent??0
        this.rate[1].tips = res.mileage_standard_percent??0
        this.mileage_standard = res.mileage_standard??200

        //raking
        this.ranking.data = []
        res.service_stat_list.forEach(item => {
          this.ranking.data.push({
            name: item.name,
            value: item.total_charge_kwh
          })
        })
        //深度拷贝刷新
        this.titleItem = JSON.parse(JSON.stringify(this.titleItem))
        this.ranking = JSON.parse(JSON.stringify(this.ranking))
        this.rate = JSON.parse(JSON.stringify(this.rate))
      }).catch(err => {
        console.log(err)
      }).finally(() => {
          this.loading = false
      })
    }

  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
