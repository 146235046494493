<template>
  <div>
    <dv-loading v-if="loading" style="height: 400px;"></dv-loading>
    <Chart v-else :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {formatTime} from "@/utils";
export default {
  data () {
    return {
      loading:true,
      cdata: {
        category: [

        ],
        lineData: [

        ],
        barData: [

        ],
        rateData: []
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    // this.setData();
    //定期获取数据
    this.getData();
    this.timer = setInterval(() => {
      this.getData();
    }, 6000);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  methods: {
    // 根据自己的业务情况修改

    setData (data) {
      this.cdata.category = [];
      this.cdata.lineData = [];
      this.cdata.barData = [];
      this.cdata.rateData = [];
      data.forEach(item => {
        this.cdata.category.push(item.stat_date);
        this.cdata.lineData.push(item.total_charge_kwh);
        this.cdata.barData.push(item.total_discharge_kwh);
        this.cdata.rateData.push(item.total_mileage);
      });
    },

    getData(){
      this.$api.screen.getRunInfo({
        //取当前日期
        date:formatTime(new Date(), 'yyyy-MM-dd'),
        company_id:1,
      }).then(res=>{
        this.setData(res);
      }).catch(err=>{
        console.log(err);
      }).finally(()=>{
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>