<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon" fill="#FFF"></icon>
        </span>
        <div class="d-flex">
          <span class=" text mx-2">里程排行</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box" :style="loading?'height: 80%':''">
        <dv-loading v-if="loading"></dv-loading>
        <dv-scroll-board v-else class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading:true,
      config: {
        header: ['编号', '今日里程'],
        data: [
          // ['中维1#', "<span  class='colorGrass'>100km</span>"],
          // ['中维2#', "<span  class='colorGrass'>100km</span>"],
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50,80, 100],
        align: ['center'],

      }
    }
  },

  //定时获取
  mounted() {
    this.getData()
    this.timer = setInterval(() => {
      // this.config.data = [
      //   ['中维1#', "<span  class='colorGrass'>100km</span>"],
      //   ['中维2#', "<span  class='colorGrass'>100km</span>"],
      // ]
      this.getData()
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 点击事件
    getData(){
      //axios请求
      this.config.data = []
      this.$api.screen.getMileageSort({
        date:Date.now(),
        company_id:1,
      }).then(res=>{
        res.forEach(item=>{
          this.config.data.push([item.device_out_serial, '<span  class="colorGrass">'+item.today_mileage+'km</span>'])
        })
        //深度拷贝刷新
        this.config = JSON.parse(JSON.stringify(this.config))
        console.log(res)
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
        console.log('finally')
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 300px;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 270px;
      height: 340px;
    }
  }
}
</style>
