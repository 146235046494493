<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        indicatorData: [
          { name: "云南华联锌铟", max: 5 },
          { name: "漳平龙刚", max: 5 },
          { name: "中维动力", max: 5 },
        ],
        dataBJ: [
          [3,1,1],
        ],
        dataGZ: [
          // [84, 94, 140, 2.238, 68, 18, 22],
        ],
        dataSH: [
          // [91, 45, 125, 0.82, 34, 23, 1],
        ]
      }
    }
  },
  components: {
    Chart,
  }
}
</script>